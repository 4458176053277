<template>
  <div class="row">
    <div class="col-12">
      <h3 class="mb-2">
        <i class="la la-list font-size-h3 text-secondary"></i>
        Minha lista de projetos
      </h3>
      <div class="w-100 mb-3">
        <span class="mr-2">
          <i class="la la-filter text-secondary"></i> Filtros:
        </span>
        <button
          class="btn btn-secondary mr-2"
          :class="{ active: filtroSelecionado == filtro }"
          v-for="(filtro, key) in filtros"
          :key="key"
          @click="filtroSelecionado = filtro"
        >
          {{ filtro.name }}
        </button>
      </div>
      <div class="row">
        <div
          class="col-12 d-flex flex-center p-12"
          v-if="!itensFiltrados.length"
        >
          <span class="font-italic">Nenhum projeto para exibir.</span>
        </div>
        <div class="col-xl-4" v-for="(item, key) in itensFiltrados" :key="key">
          <div class="card card-custom card-stretch gutter-b">
            <div class="card-body d-flex flex-column">
              <div class="flex-grow-1 mb-5">
                <span class="d-block text-muted">
                  <i :class="getIcon(item.tipo_plano_investimento)"></i>
                  {{ item.descricao_tipo_plano_investimento }}
                </span>
                <b-link
                  :to="{
                    name: 'pip_ver',
                    params: { idplano_investimento: item.idplano_investimento }
                  }"
                  class="
                    text-dark
                    font-weight-bolder
                    text-hover-primary
                    font-size-h4
                  "
                  :title="item.titulo"
                >
                  {{ cutString(item.titulo, 85) }}
                </b-link>
                <div
                  class="
                    d-flex
                    justify-content-between
                    align-items-center
                    pr-2
                    mb-4
                  "
                >
                  <span class="text-muted font-weight-bold font-size-lg">
                    {{ formatDate(item.data_cadastro) }}
                  </span>
                  <span class="badge" :class="'badge-' + item.badge_status">
                    {{ item.descricao_status }}
                  </span>
                </div>
                <p
                  class="text-dark-50 font-weight-normal font-size-lg"
                  :title="item.objetivo"
                >
                  {{ cutString(item.objetivo, 200) }}
                </p>
                <p class="text-dark-75 font-weight-normal font-size-lg mb-0">
                  Atividade:
                  <span class="text-dark-50 font-weight-normal">
                    {{ item.nome_plano_investimento }}
                  </span>
                </p>
              </div>
              <div class="d-flex justify-content-center mb-4">
                <b-btn
                  variant="primary"
                  size="lg"
                  :to="{
                    name: 'pip_ver',
                    params: { idplano_investimento: item.idplano_investimento }
                  }"
                >
                  Abrir
                </b-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "listaitens",
  props: {
    plans: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      filtros: [
        {
          name: "Todos",
          value: null
        },
        {
          name: "Plano de Investimento Rural",
          value: 1
        },
        {
          name: "Plano de Negócios",
          value: 2
        }
      ],
      filtroSelecionado: "Todos"
    };
  },
  computed: {
    itensFiltrados() {
      return this.filtroSelecionado.value == null
        ? this.plans
        : this.plans.filter(
            (item) =>
              item.tipo_plano_investimento == this.filtroSelecionado.value
          );
    }
  },
  methods: {
    getIcon(sigla_tipo) {
      return sigla_tipo == 1 ? "la la-briefcase" : "la la-industry";
    },
    cutString(string, size = 100) {
      return string.slice(0, size) + (string.length > size ? "..." : "");
    },
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    }
  }
};
</script>
