<template>
  <div>
    <div class="row">
      <div class="col-xl-4">
        <Perfil :user="user" />
      </div>
      <div class="col-xl-4">
        <Cadastro
          :title="PP.title"
          :descricao="PP.descricao"
          :tipo="PP.tipo"
          :icon="PP.icon"
        ></Cadastro>
      </div>
      <div class="col-xl-4">
        <Cadastro
          :title="PN.title"
          :descricao="PN.descricao"
          :tipo="PN.tipo"
          :icon="PN.icon"
        ></Cadastro>
      </div>
    </div>
    <ListaItens :plans="plans" />
  </div>
</template>

<script>
import Perfil from "@/view/content/dashboard/Perfil.vue";
import Cadastro from "@/view/content/dashboard/Cadastro.vue";
import ListaItens from "@/view/content/dashboard/ListaItens.vue";
import ApiMixin from "@/core/mixins/api.mixin.js";
import HelpersMixin from "@/core/mixins/helpers.mixin.js";

export default {
  name: "dashboard",
  mixins: [ApiMixin, HelpersMixin],
  components: {
    Perfil,
    Cadastro,
    ListaItens
  },
  data() {
    return {
      manual: {
        session: "dashboard",
        tipo: 0
      },
      apiResource: "dashboard",
      parentRoute: null,
      user: {},
      plans: [],
      PP: {
        tipo: "PP",
        icon: "la-briefcase",
        title: "Plano de Investimento Rural",
        descricao: `Aqui você poderá realizar de forma intuitiva a
          análise econômico-financeira para projetos com foco em
          atividades agrícolas de diversas cadeias produtivas, como
          piscicultura, apicultura, ovinocaprinocultura, fruticultura,
          entre tantas outras.`
      },
      PN: {
        tipo: "PN",
        icon: "la-industry",
        title: "Plano de Negócios",
        descricao: `Aqui você poderá realizar de forma intuitiva a
          análise econômico-financeira para projetos de pequeno e
          médio porte, com foco em empreendimentos urbanos ou rurais
          do setor agroindustrial, comércio e serviços.`
      }
    };
  },
  methods: {
    getPageData() {
      const requests = [this.Api.GetList()];
      const resolveFuntion = (res) => {
        // Requests index's
        const R_GETLIST = 0;

        this.user = res[R_GETLIST].usuario;
        this.plans = res[R_GETLIST].planos;
      };

      this.ApiGetPageData(requests, resolveFuntion);
    }
  },
  mounted() {
    this.getPageData();
    this.initManual(this.manual.session, this.manual.tipo);
  }
};
</script>
