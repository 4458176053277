<template>
  <div class="card card-custom gutter-b card-stretch">
    <div class="card-header border-0">
      <div class="card-title font-weight-bolder font-size-h3">
        <i class="la la-id-card icon-2x text-secondary mr-2"></i>
        Perfil
      </div>
    </div>
    <div class="card-body d-flex flex-column pt-0">
      <div class="perfil-foto align-self-center">
        <img :src="user.foto ? user.foto : imgDefault" />
        <b-button size="xs" variant="primary" pill :to="editPhotoRoute">
          <i class="fa fa-camera pr-0"></i>
        </b-button>
      </div>
      <div class="text-center mb-3">
        <h4>{{ user.nome }}</h4>
        <span> {{ user.municipio }} - {{ user.estado }}, {{ user.pais }} </span>
      </div>
      <small class="text-muted">E-mail</small>
      <span class="d-block mb-2">{{ user.email }}</span>
      <div class="text-center">
        <b-button variant="outline-primary" class="mr-2" :to="editPassRoute">
          Editar senha
        </b-button>
        <b-button variant="outline-primary" :to="editRoute">
          Editar dados pessoais
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Perfil",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      imgDefault: process.env.BASE_URL + "media/users/default.jpg",
      editRoute: {
        name: "profile"
      },
      editPhotoRoute: {
        name: "profile_photo"
      },
      editPassRoute: {
        name: "profile_password"
      }
    };
  }
};
</script>

<style lang="scss">
.perfil-foto {
  position: relative;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  margin-bottom: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .btn {
    position: absolute;
    padding: 1px 6px 3px;
    right: 7px;
    bottom: 7px;
  }
}
</style>
