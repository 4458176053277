<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0">
      <div class="card-title font-weight-bolder font-size-h3">
        <i class="la icon-2x text-secondary mr-2" :class="icon"></i>
        {{ title }}
      </div>
    </div>
    <div class="card-body d-flex flex-column">
      <p class="text-dark-50 font-weight-normal font-size-lg">
        {{ descricao }}
      </p>
      <div class="d-flex flex-grow-1 align-items-center justify-content-center">
        <b-button
          class="font-size-h3"
          size="lg"
          variant="primary"
          :to="{ name: 'pip_new', params: { tipo } }"
        >
          Cadastrar novo
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "cadastro",
  props: {
    title: String,
    descricao: String,
    tipo: String,
    icon: String
  }
};
</script>
